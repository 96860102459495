import { Header, Item, Segment, Button } from "semantic-ui-react";

import { formatSkill } from "../../utils/Strings.utils";

export const SkillsList = ({skills}) => {
    if (!Array.isArray(skills)) return NoResults();
    if (skills.length === 0) return NoResults();

    return (
        <div>
            <Segment>
                <Item.Group relaxed unstackable divided>
                    {skills.map((skill, index) => (
                        <Button
                            basic
                            color="teal"
                            key={index}
                        >
                            {formatSkill(skill)}
                        </Button>
                    ))}
                </Item.Group>
            </Segment>
        </div>
    )
}

const NoResults = () => {
    return (
        <Segment attached="top">
            <Header as="h4" content={"Aucun résultat"}/>
        </Segment>
    )
}