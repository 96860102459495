import axios from "axios";

const EMPTY_USER_CODE = 'UserInfoIsEmpty';

const requestCourses = async (user,namespace,interests) => {
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    try {
        if (Array.isArray(interests)) interests = interests.join(',');
        if (typeof(interests) != "string") interests = '';

        const url = BACKEND_ROUTE + "/course/recommend"
        const config = {
            params: {
                user,
                interests,
                namespace,
            }
        }
        const response = await axios.get(url, config);
        if (response.data.code === EMPTY_USER_CODE) return {results:null, code:EMPTY_USER_CODE};
        if (!response.data.hasOwnProperty('results')) return {results:null};
        return {results:response.data.results};
    } catch (error) {
        console.log('Error at requestCourses() -> ',error); // DEBUG
        return {results:null};
    }
};

function coursesAreCorrect(courses){
    if (!Array.isArray(courses)) return false;
    return true;
}


async function selectCourse(user,course){
    try {
        const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
        const data = {user, course}
        const url = BACKEND_ROUTE + '/course/select';
        const response = await axios.post(url, data);
        const succesful = response.status === 200;
        return succesful;            
    } catch (error) {
        console.log('Error selecting course: ',error.message);
        return false;
    }
}

async function unselectCourse(user,course){
    try {
        const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
        const data = {params:{user, course}}
        const url = BACKEND_ROUTE + '/course/select';
        const response = await axios.delete(url, data);
        const succesful = response.status === 200;
        return succesful;            
    } catch (error) {
        console.log('Error selecting course: ',error.message);
        return false;
    }
}

export {
    requestCourses,
    coursesAreCorrect,
    selectCourse,
    unselectCourse,
};