import { Header, Item, Segment } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { JobCard } from "./JobCard";

export const JobContainer = ({jobs}) => {
    return (
        <div className="page-container">
            <Grid stackable>
                <Grid.Row columns={1}>
                    <Grid.Column className="padless-column">
                        <JobList jobs={jobs}></JobList>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
        
    )
}

export const JobList = ({jobs}) => {
    if (!Array.isArray(jobs)) return NoResults();
    if (jobs.length === 0) return NoResults();

    return (
        <div>
            <Segment attached="top">
                <Header as="h4" content={jobs.length + " résultats"}/>
            </Segment>

            <Segment>
                <Item.Group relaxed unstackable divided>
                    {jobs.map((job, i) => (
                        <JobCard key={i} job={job} />
                    ))}
                </Item.Group>
            </Segment>
        </div>
    )
}


const NoResults = () => {
    return (
        <Segment attached="top">
            <Header as="h4" content={"Aucun résultat"}/>
        </Segment>
    )
}