import { Modal, Button, Header, Divider } from "semantic-ui-react";
import { processCourseSelection } from "../../utils/Courses.utils";

export const CourseDetails = ({course, modalIsVisible, showModal}) => {
    var {
        title, mediumDescription, 
        url, publisher, provider
    } = processCourseSelection(course);

    if (publisher == "") publisher = "Not defined";

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const user = params.get('user');

    return (
        <Modal
            open={modalIsVisible}
            onClose={()=>{showModal(false)}}
            centered={true}
            closeIcon
            size="large"
        >
            <Modal.Header>{title}</Modal.Header>

            <Modal.Content scrolling>
                <Modal.Description>
                    {mediumDescription}
                </Modal.Description>
                <br />
                <div>
                    <b>Éditeur:</b> {publisher}
                    <br />
                    <b>Prestataire:</b> {provider}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    as="a"
                    color="blue"
                    content="En lire plus" // Read Mode
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                />
            </Modal.Actions>
        </Modal>
    )
}