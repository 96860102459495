import { Item, Icon, Label } from "semantic-ui-react";
import { processJob } from "../../utils/Jobs.utils";

import { JobDetails } from "./JobDetails";

import React, { useState } from "react";


export const JobCard = ({job}) => {
    const {
        title, shortDescription, 
        missingSkills, matchingSkills, score,
    } = processJob(job);

    const [jobDetailsModalIsVisible, showJobDetailsModal] = useState(false);

    const openModal = () => showJobDetailsModal(true);

    return (
        <>
            <JobDetails
                showJobDetailsModal={showJobDetailsModal}
                jobDetailsModalIsVisible={jobDetailsModalIsVisible}
                job={job}
            ></JobDetails>
            <Item onClick={() => openModal()}>
                <Item.Content>
                    <Item.Header as="a">
                        {title}
                    </Item.Header>
                    <Item.Description>
                        {shortDescription}
                    </Item.Description>
                    {/** Summary - Missing and Matching **/}
                    <Item.Meta>
                        <span>
                            <Icon color="green" name="check" />
                            {" "}{matchingSkills.length}{" "}
                            {"compétences matchées"}{/**Matching Skills*/}
                        </span>
                        <span>
                            <Icon color="red" name="remove" />
                            {" "}{missingSkills.length}{" "}
                            {"compétences manquantes"}{/**Missing Skills*/}
                        </span>
                    </Item.Meta>
                    {/** Relevancy - Score **/}
                    <Item.Meta>
                        <Label content={"Pertinence"} />{/**Relevancy*/}
                        <Label
                            color={job.relevancy >= 0.8 ? "blue" : null}
                            circular
                            basic
                            content={score.toFixed(1)}
                        />
                    </Item.Meta>
                    {/** <Item.Meta className="item-margin">{formattedDate}</Item.Meta> **/}
                </Item.Content>
            </Item>
        </>
    )
}