import { Header, Item, Segment } from "semantic-ui-react";
import { CourseCard } from "./CourseCard";

export const CourseSelections = ({course_selections}) => {
    const courses = course_selections;

    if (!Array.isArray(courses)) return NoResults();
    if (courses.length === 0) return NoResults();

    return (
        <div>
            <Header as="h2" content={'Formations sélectionnées'}/> {/**Selected Trainings*/}

            <Segment>
                <Item.Group relaxed unstackable divided>
                    {courses.map((course, i) => (
                        <CourseCard key={i} course={course} />
                    ))}
                </Item.Group>
            </Segment>
        </div>
    )
}


const NoResults = () => {
    return (
        <>
            <Header as="h2" content={'Formations sélectionnées'}/> {/**Selected Trainings*/}
            <Segment attached="top">
                <Header as="h4" content={"L'utilisateur n'a pas sélectionné de formations"}/>
            </Segment>
        </>
    )
}