const LENGTH_SHORT_DESCRIPTION = 400;
const LENGTH_MEDIUM_DESCRIPTION = 800;

function processJob(job){
    const jobInfo = {
        'title':'',
        'description':'',
        'shortDescription': '',
        'mediumDescription': '',
        'matchingSkills':[],
        'missingSkills':[],
        'score': 0,
        'time':'',
        'url':'',
    }

    if (job == null || typeof(job) != "object") return jobInfo;

    if (typeof(job.title) === "string") jobInfo.title = job.title;
    if (typeof(job.description) === "string"){
        jobInfo.description = job.description;
        // Short
        var shortDesc = job.description.substr(0,LENGTH_SHORT_DESCRIPTION);
        if (shortDesc.length === LENGTH_SHORT_DESCRIPTION) shortDesc += "...";
        jobInfo.shortDescription = shortDesc;
        // Medium
        var medDesc = job.description.substr(0,LENGTH_MEDIUM_DESCRIPTION);
        if (medDesc.length === LENGTH_MEDIUM_DESCRIPTION) medDesc += "...";
        jobInfo.mediumDescription = medDesc;
    } 
    
    if (Array.isArray(job.reasoning)){
        jobInfo.matchingSkills = job.reasoning;
    }

    if (Array.isArray(job.missing_skills)){
        jobInfo.missingSkills = job.missing_skills;
    }

    if (typeof(job.score) === "number"){
        jobInfo.score = job.score;
    }

    if (typeof(job.time) === "string"){
        jobInfo.time = job.time;
    }

    if (typeof(job.url) === "string"){
        jobInfo.url = job.url;
    }

    return jobInfo;
}

export {processJob};