import React from 'react';
import { CourseSelections } from '../components/profile/CourseSelections';
import { userIsCorrect } from "../utils/Parameters.utils";
import { getUserInformation } from "../services/Profile.service";

import { Header, Segment } from "semantic-ui-react";

import withRouter from '../components/withRouter';
import { EdTechData } from '../components/profile/EdTechData';

const EMPTY_USER_CODE = 'UserInfoIsEmpty';
const USER_DOESNT_EXIST_MESSAGE = "User Doesn't exist";

class ProfilePage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            'selectedCourses': [],
            'userSkills': [],
            'userEmail': null,
            'userIsCorrect': false,
            'fetchingData': false,
            'user': null,
            'edtechsData': null,
            'userInfoIsEmpty': false,
            'userDoesntExist': false,
        };

        const user = props.searchParams.get('user');
        const isCorrect = userIsCorrect(user);

        if (isCorrect){
            this.state.user = user;
            this.state.fetchingData = true;
            this.state.userIsCorrect = true;
        }
    }

    componentDidMount(){
        const user = this.state.user;
        getUserInformation(user).then((response)=>{

            const {
                //full_name,
                skills,
                //identifier,
                email,
                selections,
                data_edtechs,
                code,
                error,
            } = response;

            const userInfoIsEmpty = (code === EMPTY_USER_CODE);
            const userDoesntExist = (error === USER_DOESNT_EXIST_MESSAGE);

            this.setState({
                selectedCourses: selections,
                userEmail: email,
                userSkills: skills,
                fetchingData: false,
                edtechsData: data_edtechs,
                userInfoIsEmpty: userInfoIsEmpty,
                userDoesntExist:userDoesntExist,
            });
        })
    }

    render(){
        if (!this.state.userIsCorrect) return EmptyUser();
        if (this.state.fetchingData) return LoadingMessage();
        if (this.state.userInfoIsEmpty === true) return UserInfoIsEmpty();
        if (this.state.userDoesntExist) return UserDoesntExist();
        
        const selectedCourses = this.state.selectedCourses;
        //const skills = this.state.userSkills;
        const email = this.state.userEmail;
        const edtechsData = this.state.edtechsData;

        try {
            const rendered = (
                <div>
                    <center><h1>Profil de l'utilisateur</h1></center> {/**User Profile*/}
                    <Header as="h2" content={'Informations Générales'}/> {/** General Information */}
                    <Segment>
                        <b>E-mail: </b> {email}
                    </Segment>
                    
                    <CourseSelections course_selections={selectedCourses}></CourseSelections>
                    <br />
                    <EdTechData data={edtechsData}></EdTechData>
                </div>
            );
            return rendered;  
        } catch (error) {
            console.log(error);
            return ErrorMessage();
        }
    }
}

const EmptyUser = () => {
    return (
        <div>
            <h2>No User was Defined</h2>
        </div>
    )
}

const UserDoesntExist = () => {
    return (
        <div>
            <h2>User Doesn't exist.</h2>
        </div>
    )
}

const ErrorMessage = () => {
    return (
        <div>
            <h2>An Error Ocurred while recommending training programs.</h2>
        </div>
    )
}

const UserInfoIsEmpty = () => {
    return (
        <div>
            <h2>Aucune donnée n'a été trouvée pour votre profil, veuillez compléter votre profil en vous rendant sur une des plateformes proposées dans VisionsGalaxy</h2>
        </div>
    )
}

const LoadingMessage = () => {
    return (
        <div>
            <h2>Loading User Profile...</h2>
        </div>
    )
}

// With Router passes URL info to Class Component
export default withRouter(ProfilePage);