import { Header, Segment, Item, List } from "semantic-ui-react";
import { EdTechCard } from "./EdTechCard";

export const EdTechData = ({data}) => {
    if (data === null) return EmptyData();

    const {
        orientoi,
        jobReady,
        justSalaire,
    } = data;

    const hasOrientoiData = (orientoi !== null && typeof(orientoi) === "object");
    const hasJobReadyData = (jobReady !== null && typeof(jobReady) === "object");
    const hasJustSalaireData = (justSalaire !== null && typeof(justSalaire) === "object");

    if (!hasOrientoiData && !hasJobReadyData && !hasJustSalaireData) return EmptyData();

    if (hasOrientoiData) processOrientoiData(orientoi);
    if (hasJobReadyData) processJobReadyData(jobReady);
    if (hasJustSalaireData) processJustSalaireData(justSalaire);

    return (
        <>
            <Header as="h2" content={"Données disponibles"}/> {/**Data Available*/}
            <Segment>
                <Item.Group relaxed unstackable divided>
                    <List>
                    { hasOrientoiData
                        ? <EdTechCard data={orientoi}></EdTechCard>
                        : <></>
                    }

                    { hasJobReadyData
                        ? <EdTechCard data={jobReady}></EdTechCard>
                        : <></>
                    }

                    { hasJustSalaireData
                        ? <EdTechCard data={justSalaire}></EdTechCard>
                        : <></>
                    }
                    </List>
                </Item.Group>
            </Segment>
        </>
    )
}

function EmptyData(){
    return (
        <>
            <Header as="h2" content={"Services Connectés"}/> {/**Conected Services*/}
            <Segment attached="top">
                <Header as="h4" content={"L'utilisateur n'a pas enregistré d'informations d'edtechs"}/>
                {/**The user hasn't registered information from edtechs*/}
            </Segment>
        </>
    )
}

function processOrientoiData(data){
    data.title = "Métiers visés et personnalité";
    var description = data.original_data;
    // Split line if (. . ), if (%. ) or if (. Réaliste)
    const regex = /(\. \. )|((?<=%)\. )|((?<=.) (?=Réaliste))/g
    description = description.replace(regex,'\n');
    var lines =  description.split('\n');

    data.html = (
        <>
                {
                lines.map((line, i) => {
                    if (typeof(line) !== "string" || line.length === 0) return;

                    /**/
                    if (line.match('.*%$') && line.split('. ').length > 1){
                        const percentLabel = line.split('. ')[0];
                        const percentValue = line.split('. ')[1];
                        return (
                            <>
                            <ul>
                                <li>
                                    <b>{percentLabel}{': '}</b>{percentValue}
                                </li>
                            </ul>
                            </>
                        );
                    }
                    /**/

                    if (line.length < 50){
                        return (
                            <>
                            <Header as="h3">{line}</Header><br />
                            </>
                        )
                    }

                    return (
                        <p>{capitalizeFirstLetter(line)}</p>
                    )
                })}
        </>
    )
}

function processJobReadyData(data){
    data.title = "Compétences douces";
    var description = data.original_data;
    const regex = /(\. )/g
    description = description.replace(regex,'\n');
    var skills =  description.split('\n');
    data.html = (
        <>
            <ul>
                {skills.map((skill, i) => {
                    if (typeof(skill) !== "string" || skill.length === 0) return;
                    return (
                        <li>{capitalizeFirstLetter(skill)}</li>
                    )
                })}
            </ul>
        </>
    )
}

function processJustSalaireData(data){
    data.title = "Compétences dures";
    var description = data.original_data;
    const regex = /(?<=\w|\n),(?=\w)/g
    description = description.replace(regex,'\n');
    var skills =  description.split('\n');
    data.html = (
        <>
            <ul>
                {skills.map((skill, i) => {
                    if (typeof(skill) !== "string" || skill.length === 0) return;
                    return (
                        <li>{capitalizeFirstLetter(skill)}</li>
                    )
                })}
            </ul>
        </>
    )
}

function capitalizeFirstLetter(string){
    const firstLetter = string.charAt(0);
    const newString = firstLetter.toUpperCase() + string.slice(1);
    return newString;
}