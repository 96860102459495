import { Modal, Header } from "semantic-ui-react";
import { SkillsList } from "../skills/Skills";

export const EdTechDataDetail = ({data, modalIsVisible, showModal}) => {
    var {
        html, skills, title
    } = data;

    if (typeof(skills) == "string"){
        skills = skills.split(',')
    }

    return (
        <Modal
            open={modalIsVisible}
            onClose={()=>{showModal(false)}}
            centered={true}
            closeIcon
            size="large"
        >
            <Modal.Header>{title}</Modal.Header>

            <Modal.Content scrolling>
                {html}
                <br />
                <Header as="h2">Compétences</Header>
                <SkillsList skills={skills}></SkillsList>
            </Modal.Content>
        </Modal>
    )
}