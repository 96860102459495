import { Modal } from "semantic-ui-react";

import { CourseContainer } from "./CourseList";
import { userIsCorrect } from "../../utils/Parameters.utils";
import { requestCourses } from "../../services/Courses.service";

import withRouter from '../withRouter';
import React from "react";

class CourseRecommendations extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            'courses': [],
            'fetchingData': false,
            'user': null,
            'userIsCorrect': false,
            'interests': [],
            'namespace': '',
            'modalIsVisible': false,
            'showModal': ()=>{},
        }

        if (Array.isArray(props.interests)){
            this.state.interests = props.interests;
        }

        const user = props.searchParams.get('user');
        const isCorrect = userIsCorrect(user);

        const namespace = props.searchParams.get('namespace');

        if (isCorrect){
            this.state.user = user;
            this.state.fetchingData = true;
            this.state.userIsCorrect = true;
            this.state.namespace = namespace;
        }
        
        if (typeof(props.modalIsVisible) == 'boolean'){
            this.state.modalIsVisible = props.modalIsVisible;
        }

        if (typeof(props.showModal) == "function"){
            this.state.showModal = props.showModal;
        }
        
    }

    componentWillReceiveProps(nextProps) {
        const state = {}

        if (this.state.modalIsVisible !== nextProps.modalIsVisible) state.modalIsVisible = nextProps.modalIsVisible;
        if (this.state.showModal !== nextProps.showModal) state.showModal = nextProps.showModal;
        if (Object.keys(state).length === 0) return;

        if (state.modalIsVisible === true){
            state.fetchingData = true;
            const user = this.state.user;
            const namespace = this.state.namespace;
            const interests = nextProps.interests;
            requestCourses(user, namespace, interests).then((response)=>{
                const courses = response.results;
                this.setState({
                    courses: courses,
                    fetchingData: false
                });
            });
        }

        this.setState(state);
    }

    render(){
        const {
            modalIsVisible,
            showModal,
            courses,
            fetchingData,
        } = this.state;

        if (fetchingData === true) return (
            <Modal
                open={modalIsVisible}
                onClose={()=>showModal(false)}
                centered={true}
                closeIcon
                size="large"
            >
                {LoadingMessage()}
            </Modal>
        )

        return (
            <Modal
                open={modalIsVisible}
                onClose={()=>showModal(false)}
                centered={true}
                closeIcon
                size="large"
            >
                <Modal.Content scrolling>
                    <CourseContainer courses={courses}></CourseContainer>
                </Modal.Content>
            </Modal>
        );
    }
}

const LoadingMessage = () => {
    return (
        <div>
            <h2>Loading Courses...</h2>
            <br /><br /><br /><br /><br /><br />
        </div>
    )
}

// With Router passes URL info to Class Component
export default withRouter(CourseRecommendations);