import { List } from "semantic-ui-react";
import { EdTechDataDetail } from "./EdTechDetails";

import React, { useState } from "react";

import "./Profile.css";

const SHORT_DESC_LENGTH = 500;

export const EdTechCard = ({data}) => {
    const [modalIsVisible, showModal] = useState(false);

    var {
        title, original_data, skills
    } = data;

    var description = original_data;

    if (typeof(description) !== "string") return EmptyCard();
    const openModal = () => showModal(true);

    return (
        <>
            <EdTechDataDetail
                showModal={showModal}
                modalIsVisible={modalIsVisible}
                data={data}
            ></EdTechDataDetail>
            <List.Item onClick={openModal} className="connectedService">
                <List.Icon name="checkmark" />
                <List.Content>
                    <List.Header as="a">{title}</List.Header>
                </List.Content>
            </List.Item>
        </>
    )
}

function EmptyCard(){
    return (
        <>
        </>
    )
}


function getReducedDescription(description, length){
    if (typeof(description) !== "string") return null;

    var shortDesc = description.substr(0,length);
    if (shortDesc.length === length) shortDesc += "...";
    return shortDesc;
}