import axios from "axios";

const EMPTY_USER_CODE = 'UserInfoIsEmpty';

const getUserInformation = async (user) => {
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    try {
        console.log('Requesting Selected Trainings...');
        const url = BACKEND_ROUTE + "/profile"
        const config = {
            params: {
                user,
            }
        }
        const response = await axios.get(url, config);

        //if (response.data.code === EMPTY_USER_CODE) return {results:null, code:EMPTY_USER_CODE};
        //if (response.data == null || response.data.results == null) return {results:null, code:EMPTY_USER_CODE};

        const results = response.data;
        return results;
    } catch (error) {
        console.log('Error at requestJobs() -> ',error); // DEBUG
        return {results:null};
    }
};

export {
    getUserInformation,
};