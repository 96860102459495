import { Modal, Button, Header, Divider } from "semantic-ui-react";
import { processJob } from "../../utils/Jobs.utils";

import React, { useState } from "react";

import { formatSkill } from "../../utils/Strings.utils";
import CourseRecommendations from "../courses/CourseRecommendations";

import "./jobDetails.css";

function clickOnMissingSkill(skill, selectedSkills, setSelectedSkills){
    if (selectedSkills.includes(skill)){
        const index = selectedSkills.findIndex( d => d === skill);
        if (index >= 0) selectedSkills.splice(index,1);
    } else {
        selectedSkills.push(skill);
    }
    setSelectedSkills([...selectedSkills]);
}

export const JobDetails = ({job, jobDetailsModalIsVisible, showJobDetailsModal}) => {
    const {
        title, mediumDescription, 
        missingSkills, matchingSkills,
        url
    } = processJob(job);

    const [selectedSkills, setSelectedSkills] = useState([]);
    const [recommendationsAreVisible, showCourseRecommendations] = useState(false);

    const openModal = () => showCourseRecommendations(true);

    const closeModal = () => {
        showJobDetailsModal(false);
        setSelectedSkills([]);
    }


    return (
        <Modal
            open={jobDetailsModalIsVisible}
            onClose={closeModal}
            centered={true}
            closeIcon
            size="large"
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>

                    {mediumDescription}

                    <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                    >(voir l'offre)</a>

                    <Divider section />

                    <Header size="medium">{"Compétences manquantes"}</Header>
                    <p>
                        Cliquer sur les compétences et trouver des formations
                    </p>

                    {missingSkills.length === 0 ? (
                        "No Missing Skills"
                    ):(
                        missingSkills.map((skill, index)=>(
                            <button
                                inverted
                                skill={skill}
                                //appereance="subtle"
                                color="red"
                                key={index}
                                className={
                                    selectedSkills.includes(skill)
                                    ? "missing-skill-active"
                                    : "missing-skill"
                                }
                                onClick={(event, params)=>{clickOnMissingSkill(skill, selectedSkills,setSelectedSkills)}}
                            >
                                {formatSkill(skill)}
                            </button>
                        ))
                    )}

                    <Divider section />

                    <Header size="medium">{"Compétences matchées"}</Header>
                    {matchingSkills.length === 0 ? (
                        "No Matching Skills"
                    ):(
                        matchingSkills.map((skill, index)=>(
                            <Button
                                basic
                                color="teal"
                                key={index}
                            >
                                {formatSkill(skill)}
                            </Button>
                        ))
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    content="Formations pour les compétences sélectionnées" //Search trainings for selected skills
                    onClick={() => openModal()}
                    disabled={selectedSkills.length === 0}
                    color="blue"
                />
            </Modal.Actions>
            <CourseRecommendations 
                modalIsVisible={recommendationsAreVisible}
                interests={selectedSkills}
                showModal={showCourseRecommendations}
            />
        </Modal>
    )
}