import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';

import Jobs from './pages/Jobs.page';
import CoursesPage from './pages/Courses.page';
import ProfilePage from './pages/Profile.page';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/jobs' element={<Jobs/>} />
        <Route path='/courses' element={<CoursesPage />} />
        <Route path='/profile' element={<ProfilePage />} />
      </Routes>
    </Router>
  );
}

export default App;
