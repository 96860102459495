import { Item, Icon, Label, Button } from "semantic-ui-react";
import { processCourseSelection } from "../../utils/Courses.utils";

import React, { useState } from "react";

import { CourseDetails } from "./CourseDetails";

import "./Profile.css";

export const CourseCard = ({course}) => {
    const {
        title, shortDescription, 
        provider, publisher
    } = processCourseSelection(course);

    const [modalIsVisible, showModal] = useState(false);

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const user = params.get('user');

    const openModal = () => showModal(true);

    return (
        <>
            <CourseDetails
                showModal={showModal}
                modalIsVisible={modalIsVisible}
                course={course}
            ></CourseDetails>
            <Item className={"courseSelection"} onClick={()=> openModal()}>
                <Item.Content>
                    <Item.Header as="a">
                        {title}
                    </Item.Header>
                    <Item.Description>
                        {shortDescription}
                    </Item.Description>
                    <Item.Meta>
                        {typeof(publisher) == "string" && publisher.length > 0
                            ? <><b>Éditeur: </b>{publisher} <br/></>
                            : <></>
                        }
                    </Item.Meta>
                </Item.Content>
            </Item>
        </>
    )
}