const LENGTH_SHORT_DESCRIPTION = 400;
// Medium length must be 500 since Compass only delivers a maximum of 500 characters
const LENGTH_MEDIUM_DESCRIPTION = 500;

function processCourse(course){
    const courseInfo = {
        'title':'',
        'description':'',
        'shortDescription':'',
        'mediumDescription':'',
        'newSkills':[],
        'existingSkills':[],
        'score':0,
        'url':'',
        'code':'',
    }

    if (course == null || typeof(course) != "object") return courseInfo;

    if (typeof(course.title) === "string") courseInfo.title = course.title;
    if (typeof(course.short_description) === "string"){
        courseInfo.description = course.short_description;
        // Short
        var shortDesc = course.short_description.substr(0,LENGTH_SHORT_DESCRIPTION);
        if (shortDesc.length === LENGTH_SHORT_DESCRIPTION) shortDesc += "...";
        courseInfo.shortDescription = shortDesc;
        // Medium
        var medDesc = course.short_description.substr(0,LENGTH_MEDIUM_DESCRIPTION);
        if (medDesc.length === LENGTH_MEDIUM_DESCRIPTION) medDesc += "...";
        courseInfo.mediumDescription = medDesc;
    } 

    if (Array.isArray(course.new_skills)) courseInfo.newSkills = course.new_skills;
    if (Array.isArray(course.existing_skills)) courseInfo.existingSkills = course.existing_skills;

    if (typeof(course.quality_index) == "number"){
        courseInfo.score = course.quality_index;
    }

    if (typeof(course.url) == "string"){
        courseInfo.url = course.url;
    }

    if (typeof(course.code) == "string"){
        courseInfo.code = course.code;
    }

    return courseInfo;
}

function processCourseSelection(course){
    const courseInfo = {
        'title':'',
        'description':'',
        'shortDescription':'',
        'mediumDescription':'',
        'url':'',
        'code':'',
        'provider':'',
        'publisher':'',
    }

    if (course == null || typeof(course) != "object") return courseInfo;

    if (typeof(course.title) === "string") courseInfo.title = course.title;
    if (typeof(course.description) === "string"){
        courseInfo.description = course.description;
        // Short
        var shortDesc = course.description.substr(0,LENGTH_SHORT_DESCRIPTION);
        if (shortDesc.length === LENGTH_SHORT_DESCRIPTION) shortDesc += "...";
        courseInfo.shortDescription = shortDesc;
        // Medium
        var medDesc = course.description.substr(0,LENGTH_MEDIUM_DESCRIPTION);
        if (medDesc.length === LENGTH_MEDIUM_DESCRIPTION) medDesc += "...";
        courseInfo.mediumDescription = medDesc;
    } 

    if (typeof(course.url) == "string"){
        courseInfo.url = course.url;
    }

    if (typeof(course.code) == "string"){
        courseInfo.code = course.code;
    }

    if (typeof(course.provider) == "string"){
        courseInfo.provider = course.provider;
    }

    if (typeof(course.publisher) == "string"){
        courseInfo.publisher = course.publisher;
    }

    return courseInfo;
}

export {
    processCourse,
    processCourseSelection,
};