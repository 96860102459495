import React from 'react';
import { CourseContainer } from '../components/courses/CourseList';
import { userIsCorrect } from "../utils/Parameters.utils";
import { coursesAreCorrect, requestCourses } from "../services/Courses.service";

import withRouter from '../components/withRouter';

const EMPTY_USER_CODE = 'UserInfoIsEmpty';

class CoursesPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            'courses': [],
            'userIsCorrect': false,
            'fetchingData': false,
            'user': null,
            'namespace': null,
            'userInfoIsEmpty': false,
        };

        const user = props.searchParams.get('user');
        this.state.namespace = props.searchParams.get('namespace');
        const isCorrect = userIsCorrect(user);

        if (isCorrect){
            this.state.user = user;
            this.state.fetchingData = true;
            this.state.userIsCorrect = true;
        }
    }

    componentDidMount(){
        const user = this.state.user;
        const namespace = this.state.namespace;
        requestCourses(user,namespace).then((response)=>{
            const {results, code} = response;
            const userInfoIsEmpty = (code === EMPTY_USER_CODE);

            this.setState({
                courses: results,
                fetchingData: false,
                userInfoIsEmpty: userInfoIsEmpty,
            });
        })
    }

    render(){
        if (!this.state.userIsCorrect) return EmptyUser();
        if (this.state.userInfoIsEmpty === true) return UserInfoIsEmpty();
        if (typeof(this.state.namespace) !== 'string' || this.state.namespace.length === 0) return EmptyNamespace();
        if (this.state.fetchingData) return LoadingMessage();
        const courses = this.state.courses;
        if (!coursesAreCorrect(courses)) return ErrorMessage();
        return (
            <div>
                <h1>Formations</h1>
                <CourseContainer courses={courses}></CourseContainer>
            </div>
        );
    }
}

const EmptyNamespace = () => {
    return (
        <div>
            <h2>You must define a valid Namespace</h2>
        </div>
    )
}

const EmptyUser = () => {
    return (
        <div>
            <h2>No User was Defined</h2>
        </div>
    )
}

const UserInfoIsEmpty = () => {
    return (
        <div>
            <h2>Aucune donnée n'a été trouvée pour votre profil, veuillez compléter votre profil en vous rendant sur une des plateformes proposées dans VisionsGalaxy</h2>
        </div>
    )
}

const ErrorMessage = () => {
    return (
        <div>
            <h2>An Error Ocurred while recommending training programs.</h2>
        </div>
    )
}

const LoadingMessage = () => {
    return (
        <div>
            <h2>Loading Courses...</h2>
        </div>
    )
}

// With Router passes URL info to Class Component
export default withRouter(CoursesPage);