import { Item, Icon, Label, Button } from "semantic-ui-react";
import { processCourse } from "../../utils/Courses.utils";

import React, { useState } from "react";

import { selectCourse, unselectCourse } from "../../services/Courses.service";

import { CourseDetails } from "./CourseDetails";

export const CourseCard = ({course}) => {
    const {
        title, shortDescription, 
        newSkills, existingSkills, score, code
    } = processCourse(course);

    const [modalIsVisible, showModal] = useState(false);
    const [isSelected, setSelected] = useState(course['selected'] === true);

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const user = params.get('user');

    const openModal = () => showModal(true);

    return (
        <>
            <CourseDetails
                showModal={showModal}
                modalIsVisible={modalIsVisible}
                course={course}
                courseIsSelected={isSelected}
                setSelected={setSelected}
            ></CourseDetails>
            <Item onClick={()=> openModal()}>
                <Item.Content>
                    <Item.Header as="a">
                        {title}
                    </Item.Header>
                    <Item.Description>
                        {shortDescription}
                    </Item.Description>
                    {/** Summary - Missing and Matching **/}
                    <Item.Meta>
                        <span>
                            <Icon color="blue" name="bookmark" />
                            {" "}{newSkills.length}{" "}
                            {"compétences à acquérir"}{/**New Skills*/}
                        </span>
                        <span>
                            <Icon color="orange" name="bookmark" />
                            {" "}{existingSkills.length}{" "}
                            {"compétences renforcées"}{/**Strenghtened skills*/}
                        </span>
                    </Item.Meta>
                    <Item.Meta>
                        <Label content={"Pertinence"} />{/**Relevancy*/}
                        <Label
                            color={course.relevancy >= 0.8 ? "blue" : null}
                            circular
                            basic
                            content={score.toFixed(1)}
                        />
                        
                        {
                            isSelected 
                            ? <Button
                                basic
                                color='blue'
                                content="Intéressé" // Interested
                                onClick={(e)=>{
                                    // Prevents executing parent onClick actions
                                    e.preventDefault(); 
                                    e.stopPropagation();

                                    unselectCourse(user,code).then((succesful)=>{
                                        if (succesful === true){
                                            course['selected'] = false;
                                            setSelected(false);
                                        }
                                    });
                                }}
                            /> 
                            : <Button
                                content="Ça m'intéresse" // I am interested
                                color="blue"
                                onClick={(e)=>{
                                    // Prevents executing parent onClick actions
                                    e.preventDefault(); 
                                    e.stopPropagation();

                                    selectCourse(user,course).then((succesful)=>{
                                        course['selected'] = succesful;
                                        setSelected(succesful);    
                                    });
                                }}
                            />
                        }
                    </Item.Meta>
                </Item.Content>
            </Item>
        </>
    )
}