import axios from "axios";

const EMPTY_USER_CODE = 'UserInfoIsEmpty';

const requestJobs = async (user, jobsSource, area) => {
    const BACKEND_ROUTE = process.env.REACT_APP_BACKEND_ROUTE;
    try {
        console.log('Requesting Jobs...');
        const url = BACKEND_ROUTE + "/jobs/recommend"
        const config = {
            params: {
                user,
                dataSource:jobsSource,
                area,
            }
        }
        const response = await axios.get(url, config);

        if (response.data.code === EMPTY_USER_CODE) return {results:null, code:EMPTY_USER_CODE};

        const results = response.data;
        return results;
    } catch (error) {
        console.log('Error at requestJobs() -> ',error); // DEBUG
        return {results:null};
    }
};

function jobsAreCorrect(jobs){
    if (!Array.isArray(jobs)) return false;
    return true;
}

export {
    requestJobs,
    jobsAreCorrect,
};