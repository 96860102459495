import { Modal, Button, Header, Divider } from "semantic-ui-react";
import { processCourse } from "../../utils/Courses.utils";

import { formatSkill } from "../../utils/Strings.utils";
import { selectCourse, unselectCourse } from "../../services/Courses.service";


export const CourseDetails = ({course, modalIsVisible, showModal, courseIsSelected, setSelected}) => {
    const {
        title, mediumDescription, 
        newSkills, existingSkills,
        url, code
    } = processCourse(course);

    const isSelected = courseIsSelected;

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const user = params.get('user');

    return (
        <Modal
            open={modalIsVisible}
            onClose={()=>{showModal(false)}}
            centered={true}
            closeIcon
            size="large"
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>

                    {mediumDescription}

                    <Divider section />

                    <Header size="medium">{"Compétences à acquérir"}</Header>{/**New Skills*/}
                    {newSkills.length === 0 ? (
                        "Empty"
                    ):(
                        newSkills.map((skill, index)=>(
                            <Button
                                basic
                                skill={skill}
                                //appereance="subtle"
                                color="blue"
                                key={index}
                                //onClick={(event, params)=>{clickOnMissingSkill(skill, selectedSkills,setSelectedSkills)}}
                            >
                                {formatSkill(skill)}
                            </Button>
                        ))
                    )}

                    <Divider section />

                    <Header size="medium">{"Compétences Renforcées"}</Header>{/**Strenghtened Skills*/}
                    {existingSkills.length === 0 ? (
                        "Empty"
                    ):(
                        existingSkills.map((skill, index)=>(
                            <Button
                                basic
                                color="orange"
                                key={index}
                            >
                                {formatSkill(skill)}
                            </Button>
                        ))
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {
                    isSelected 
                    ? <Button
                        color='blue'
                        content="Intéressé" // Interested
                        basic
                        onClick={()=>{
                            unselectCourse(user,code).then((succesful)=>{
                                if (succesful === true){
                                    course['selected'] = false;
                                    setSelected(false);
                                }
                            });
                        }}

                    /> 
                    : <Button
                        content="Ça m'intéresse" // I am interested
                        color="blue"
                        onClick={()=>{
                            selectCourse(user,course).then((succesful)=>{
                                course['selected'] = succesful;
                                setSelected(succesful);    
                            });
                        }}
                    />

                }

                <Button 
                    as="a"
                    color="blue"
                    content="En lire plus" // Read Mode
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                />
            </Modal.Actions>
        </Modal>
    )
}